@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth;
}

.gallery-images {
    @apply
    overflow-hidden
    *:block
    *:w-full
    *:h-full
    *:relative 
    *:bg-cover 
    *:bg-center 
    *:hover:cursor-pointer
    *:before:absolute 
    *:before:w-full 
    *:before:h-full 
    *:before:bg-secondary 
    *:before:transition-all 
    *:before:duration-500 
    *:before:ease-ease
    *:before:opacity-0 
    *:hover:before:opacity-50 
}

.image-container {
    @apply
    blur-xl 
    transition-[filter] 
    ease-ease
    duration-1000
}